import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import eventEmitter from '../../../../shared/foreground/eventEmitter';
import { globalState, useIsStaffProfile } from '../../../../shared/foreground/models';
import {
  useDocument,
  useGlobalTagsAsObject,
  useIsPDFViewAsHTML,
} from '../../../../shared/foreground/stateHooks';
import { useIsTtsPlayerStateForThisDocument } from '../../../../shared/foreground/stateHooks/tts';
import { setIsDocMoreActionsDropdownOpen } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/dropdowns';
import { setIsEditTagsPopoverShown } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import {
  AnyDocument,
  Category,
  DocumentThatCanBeShared,
  FirstClassDocument,
} from '../../../../shared/types';
import { ShortcutId } from '../../../../shared/types/keyboardShortcuts';
import {
  isDocumentThatCanBeShared,
  isDocumentWithPublishedDate,
  isDocumentWithTTS,
} from '../../../../shared/typeValidators';
import { useDesktopAppInfo } from '../../../../shared/utils/appInfo.desktop';
import formatPublishedDate from '../../../../shared/utils/dates/formatPublishedDate';
import delay from '../../../../shared/utils/delay';
import getDocumentAuthor from '../../../../shared/utils/getDocumentAuthor';
import getDocumentTitle from '../../../../shared/utils/getDocumentTitle';
import { AdaptiveHeaderContext } from '../../contexts';
import { useKeyboardShortcut, useKeyboardShortcutPreventDefault } from '../../hooks/useKeyboardShortcut';
import { isReaderViewUrl } from '../../utils/pathnameHelpers';
import { useShortcutsMap } from '../../utils/shortcuts';
import useLocation from '../../utils/useLocation';
import EditTagsPopover from '../Popovers/EditTagsPopover';
import {
  getAddDocNoteOption,
  getAddTagOption,
  getCopyDocUrlOption,
  getDeleteDocOption,
  getDownloadOrPrintDocOption,
  getEditMetadataOption,
  getEnablePublicLinkOption,
  getGptOption,
  getOpenOriginalOption,
  getPauseOrPlayTtsOption,
  getResetReadingProgressOption,
  getSendToKindleOption,
  getSeparatorOption,
} from './docOptions';
import { Dropdown, DropdownOption } from './Dropdown';
import styles from './Dropdown.module.css';

async function waitForMenuToAppear() {
  await delay(300);
}

export default function DropdownDocHeader({
  docId,
  parentPath,
  triggerClassName = '',
}: { docId: AnyDocument['id']; parentPath: string; triggerClassName?: string }) {
  const { headerIsHidden, setHeaderIsHidden } = useContext(AdaptiveHeaderContext);
  const shortcutsMap = useShortcutsMap();
  const history = useHistory();
  const { pathname } = useLocation();
  const [globalTagsObject] = useGlobalTagsAsObject();
  const [doc] = useDocument<FirstClassDocument>(docId);
  const isDocMoreActionsDropdownOpen = globalState(
    useCallback((state) => state.isDocMoreActionsDropdownOpen, []),
  );
  const isMainDropdownOpen = isDocMoreActionsDropdownOpen && isReaderViewUrl(pathname);

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.DocumentReaderTag],
    useCallback(async (event) => {
      event.preventDefault();
      eventEmitter.emit('open-document-header-edit-tags-popover');
    }, []),
    {
      description: 'Add / Edit tags for document (while document is open)',
    },
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.ToggleDocMoreActions],
    useCallback(async () => {
      const willShowDropdown = !isDocMoreActionsDropdownOpen;

      if (!willShowDropdown) {
        setIsDocMoreActionsDropdownOpen(willShowDropdown);
        return;
      }

      if (headerIsHidden && setHeaderIsHidden) {
        setHeaderIsHidden(false);
        await waitForMenuToAppear();
      }

      setIsDocMoreActionsDropdownOpen(true);
    }, [headerIsHidden, isDocMoreActionsDropdownOpen, setHeaderIsHidden]),
    {
      description: 'Toggle doc more actions dropdown',
    },
  );

  const isEditTagsPopoverShown = globalState(useCallback((state) => state.isEditTagsPopoverShown, []));
  const showEditTagsPopover = useCallback(() => setIsEditTagsPopoverShown(true), []);
  const hideEditTagsPopover = useCallback(() => {
    setTimeout(() => {
      setIsEditTagsPopoverShown(false);
    }, 100);
  }, []);

  useEffect(() => {
    const onEvent = async () => {
      if (setHeaderIsHidden) {
        setHeaderIsHidden(false);
        await waitForMenuToAppear();
      }
      showEditTagsPopover();
    };
    eventEmitter.on('open-document-header-edit-tags-popover', onEvent);

    return () => {
      eventEmitter.off('open-document-header-edit-tags-popover', onEvent);
    };
  }, [showEditTagsPopover, setHeaderIsHidden]);

  const triggerButtonRef = useRef<HTMLButtonElement>(null);

  const sharedAt = (doc as DocumentThatCanBeShared)?.sharedAt;
  const isPublicLinkEnabled = useMemo(() => Boolean(sharedAt), [sharedAt]);
  const showHTMLContentForPDF = useIsPDFViewAsHTML(doc?.id);
  const isPDF = doc?.category === Category.PDF;
  const isPdfViewer = isPDF && !showHTMLContentForPDF;
  const isDistributable = Boolean(doc && !doc.non_distributable);
  const isStaff = useIsStaffProfile();

  const docTitle = useMemo(() => getDocumentTitle(doc), [doc]);
  const docNotes = useMemo(() => doc?.notes, [doc]);
  const author = useMemo(() => getDocumentAuthor(doc) || '', [doc]);
  const publishedDate = useMemo(
    () =>
      doc && isDocumentWithPublishedDate(doc) && doc.published_date
        ? formatPublishedDate(doc.published_date)
        : '',
    [doc],
  );
  const desktopAppInfo = useDesktopAppInfo();

  const ttsPlayerStateForThisDocument = useIsTtsPlayerStateForThisDocument(doc?.id);

  const options = useMemo(() => {
    const shouldShowExportDocOption =
      isDistributable && (!desktopAppInfo || desktopAppInfo.osType === 'windows');
    return [
      getAddTagOption({
        onSelect: showEditTagsPopover,
        shortcut: shortcutsMap[ShortcutId.DocumentReaderTag],
      }),
      getAddDocNoteOption({ shortcut: shortcutsMap[ShortcutId.AddDocNote] }),
      getGptOption(false, doc, shortcutsMap[ShortcutId.DocGhostreader]),
      doc &&
        isDocumentWithTTS(doc, isPdfViewer) &&
        getPauseOrPlayTtsOption({
          docId: doc.id,
          isOpen: true,
          ttsPlayerStateForThisDocument,
        }),
      getSeparatorOption(),
      getEditMetadataOption({ shortcut: shortcutsMap[ShortcutId.ShowDocMetadata] }),
      getResetReadingProgressOption({ docId, shortcut: shortcutsMap[ShortcutId.ResetReadingProgress] }),
      isDistributable && getSeparatorOption(),
      isDistributable &&
        getOpenOriginalOption({ docId, shortcut: shortcutsMap[ShortcutId.OpenOriginalDoc] }),
      isDistributable && getCopyDocUrlOption({ docId, shortcut: shortcutsMap[ShortcutId.Share] }),
      isDistributable &&
        isDocumentThatCanBeShared(doc) &&
        getEnablePublicLinkOption({
          docId,
          isPublicLinkEnabled,
          isDisabled: isPdfViewer,
          onSelect: () => eventEmitter.emit('document-header-document-share-enable-option-clicked'),
          shortcut: shortcutsMap[ShortcutId.EnableOrViewPublicLink],
        }),
      shouldShowExportDocOption && getSeparatorOption(),
      shouldShowExportDocOption &&
        getDownloadOrPrintDocOption({
          docTitle,
          docNotes,
          author,
          publishedDate,
          isPdfViewer,
          printShortcut: shortcutsMap[ShortcutId.PrintDocument],
          downloadShortcut: shortcutsMap[ShortcutId.DownloadDocument],
        }),
      isStaff && getSendToKindleOption({ docId }),
      getSeparatorOption(),
      getDeleteDocOption({
        docId,
        shortcut: shortcutsMap[ShortcutId.DeleteDocument],
        callback: () => {
          history.push(parentPath);
        },
      }),
    ].filter(Boolean) as DropdownOption[];
  }, [
    isDistributable,
    desktopAppInfo,
    showEditTagsPopover,
    shortcutsMap,
    doc,
    docId,
    isPublicLinkEnabled,
    isPdfViewer,
    docTitle,
    docNotes,
    author,
    publishedDate,
    history,
    parentPath,
    ttsPlayerStateForThisDocument,
    isStaff,
  ]);

  return (
    <>
      <Dropdown
        appendToDocumentBody
        isOpen={isMainDropdownOpen}
        isSubDropdownOpen={isEditTagsPopoverShown}
        options={options}
        ref={triggerButtonRef}
        setIsOpen={setIsDocMoreActionsDropdownOpen}
        triggerClassName={triggerClassName}
        triggerShortcut={shortcutsMap[ShortcutId.ToggleDocMoreActions]}
        triggerTooltipText="More actions"
      />
      {doc && triggerButtonRef.current && (
        <EditTagsPopover
          allowFlip
          className={styles.editTagsPopover}
          doc={doc}
          globalTagsObject={globalTagsObject}
          hidePopover={hideEditTagsPopover}
          isShown={isEditTagsPopoverShown}
          popperOptions={{
            placement: 'bottom-start',
          }}
          portalDestinationElementId={`doc-header-dropdown-${docId}`}
          ref={() => undefined}
          reference={triggerButtonRef.current}
          showPopover={showEditTagsPopover}
        />
      )}
    </>
  );
}
