import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  addToShortlist,
  updateDocumentLocation,
} from '../../../shared/foreground/actions/documentLocations';
import { enableDocumentShare } from '../../../shared/foreground/actions/documentShare';
import { hideModal } from '../../../shared/foreground/actions/modals';
import { openDocumentGptSubMenu, setCmdPaletteOpen } from '../../../shared/foreground/cmdPalette';
import copyDocumentReaderUrl from '../../../shared/foreground/copyDocumentReaderUrl';
import copyDocumentUrl from '../../../shared/foreground/copyDocumentUrl';
import { setDocumentsSortMenuOpen } from '../../../shared/foreground/documentsSortMenu';
import eventEmitter from '../../../shared/foreground/eventEmitter';
import { generateEmailWarningMessage, messageCopy } from '../../../shared/foreground/messageCopy';
import { globalState } from '../../../shared/foreground/models';
import { useDocument, usePartialDocument } from '../../../shared/foreground/stateHooks';
import { useFocusedDocumentId } from '../../../shared/foreground/stateHooks/useFocusedDocument';
import useRSSFeedIdByUrl from '../../../shared/foreground/stateHooks/useRSSFeedIdByUrl';
import { setQuoteshotModalOpen } from '../../../shared/foreground/stateUpdaters/clientStateUpdaters/quoteshot';
import {
  bumpDocumentForUser,
  safeDelete,
  toggleDocumentOpened,
} from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import {
  removeDocsFromTheSameEmailAddress,
  toggleEmailSubscription,
  toggleEmailSubscriptionfromPalette,
} from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/emails';
import { resetReadingProgress } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/progressRelated';
import { toggleTag } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/tag';
import { updateDocument } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/update';
import {
  removeFeed,
  toggleDocumentFeedSubscription,
} from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import { toggleIsDocMoreActionsDropdownOpen } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/dropdowns';
import { setDocumentsListScrolled } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/lists';
import {
  setAppearancePanelOpen,
  setFocusedDocumentId as setFocusedDocumentIdUnthrottled,
} from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { useMatchingRSS } from '../../../shared/foreground/useMatchingRss';
import { exportHighlightsToClipboard } from '../../../shared/foreground/utils/exportHighlightsToClipboard';
import { exportHighlightsToFile } from '../../../shared/foreground/utils/exportHighlightsToFile';
import getUIFriendlyNameForDocumentLocation from '../../../shared/foreground/utils/getUIFriendlyNameForDocumentLocation';
import useDocumentLocations from '../../../shared/foreground/utils/useDocumentLocations';
import useGlobalStateWithFallback from '../../../shared/foreground/utils/useGlobalStateWithFallback';
import {
  AnyDocument,
  BaseDocument,
  DocumentLocation,
  FeedDocumentLocation,
  SortRule,
  SplitByKey,
  UserEvent,
} from '../../../shared/types';
import { ShortcutId } from '../../../shared/types/keyboardShortcuts';
import delay from '../../../shared/utils/delay';
import { getAdjacentDocumentIdUsingIds as getAdjacentDocumentId } from '../../../shared/utils/getAdjacentDocumentId';
import makeLogger from '../../../shared/utils/makeLogger';
import urlJoin from '../../../shared/utils/urlJoin';
import usePrevious from '../../../shared/utils/usePrevious';
import useThrottle from '../../../shared/utils/useThrottle';
import { useKeyboardShortcut, useKeyboardShortcutPreventDefault } from '../hooks/useKeyboardShortcut';
import useLeaveReadingViewIfOpen from '../hooks/useLeaveReadingViewIfOpen';
import useUserScrollable from '../hooks/useUserScrollable';
import { toggleDocumentMetadataOpen } from '../stateUpdaters/other';
import focusDocumentNoteField from '../utils/focusDocumentNoteField';
import isReaderViewUrl from '../utils/isReaderViewUrl';
import { openOriginalDocument } from '../utils/openOriginalDocument';
import { getSplitByKeyOrSplittingByKeyFromLocation } from '../utils/pathnameHelpers';
import { useShortcutsMap } from '../utils/shortcuts';
import { DeleteDocumentDialog } from './DeleteDocumentDialog';
import { DeleteFeedDialog } from './DeleteFeedDialog';
import DocumentList from './DocumentList/DocumentList';
import DocumentReader from './DocumentReader/DocumentReader';
import styles from './Documents.module.css';

const logger = makeLogger(__filename);

type ActionFunctionOptions = Omit<
  Parameters<typeof updateDocument>[2],
  'eventName' | 'userInteraction'
> & { beforeStateUpdate?: () => Promise<void>; userInteraction: string };

const defaultExport = React.memo(function Documents({
  currentSortRule,
  documentPathPrefix,
  listedDocumentIds,
  openDocumentId,
  parentPath,
  showSearchMatches,
  onEndThresholdReached,
  pageSize,
}: {
  currentSortRule?: SortRule;
  documentPathPrefix: string;
  listedDocumentIds: AnyDocument['id'][];
  openDocumentId: BaseDocument['id'] | undefined;
  parentPath: string;
  showSearchMatches?: boolean;
  onEndThresholdReached?: () => void;
  pageSize?: number;
}) {
  const { feedDocumentLocation: filterByFeedDocumentLocation } = useParams<{
    feedDocumentLocation?: FeedDocumentLocation;
  }>();
  const shortcutsMap = useShortcutsMap();
  const history = useHistory();
  const isFeedView = useMemo(
    () => Boolean(filterByFeedDocumentLocation),
    [filterByFeedDocumentLocation],
  );
  const documentScrollableRootRef = useRef<HTMLDivElement>(null);
  const focusedDocIndexRef = useRef<number | null>(null);
  const prevFocusedDocIndex = usePrevious(focusedDocIndexRef.current);
  const allEmailSubscriptions = useGlobalStateWithFallback(
    {},
    useCallback((state) => state.persistent.emailSubscriptions, []),
  );
  const leaveReadingViewIfOpen = useLeaveReadingViewIfOpen();

  // We use this for animations:
  const [documentIdBeingRemoved, setDocumentIdBeingRemoved] = useState<string | null>(null);
  const isDocumentListShown = useMemo(() => !openDocumentId, [openDocumentId]);

  const focusedDocumentId = useFocusedDocumentId();
  const [deleteFeedDialogOpen, setDeleteFeedDialogOpen] = useState(false);

  const isDocumentsSortMenuShown = globalState(
    useCallback((state) => state.isDocumentsSortMenuShown, []),
  );
  const isAppearancePanelShown = globalState(useCallback((state) => state.isAppearancePanelShown, []));
  const isCmdPaletteOpen = globalState(useCallback((state) => state.cmdPalette.isOpen, []));
  const isQuoteshotModalOpen = globalState(useCallback((state) => state.quoteshotModalOpen, []));
  const isDocumentMetadataShown = globalState(useCallback((state) => state.isDocumentMetadataShown, []));
  const isDocMoreActionsDropdownOpen = globalState(
    useCallback((state) => state.isDocMoreActionsDropdownOpen, []),
  );
  const autoAdvance = globalState(useCallback((state) => state.client.autoAdvance, []));

  const modal = globalState(useCallback((state) => state.modal, []));

  const setFocusedDocumentId: typeof setFocusedDocumentIdUnthrottled = useThrottle(
    setFocusedDocumentIdUnthrottled,
    60,
  );

  useUserScrollable(documentScrollableRootRef.current, (scrollTop) => {
    setDocumentsListScrolled(scrollTop > 0);
    /* Allows annotation bar popover to overlay YouTube video when first line is selected.
    This is to prevent the bar from getting hidden underneath and becoming inaccessible. */
    if (documentScrollableRootRef.current) {
      if (document.querySelector('#document-text-content.is-youtube-video')) {
        if (scrollTop > 20) {
          documentScrollableRootRef.current.classList.remove('allow-annotation-bar-popover-overlay');
        } else {
          documentScrollableRootRef.current.classList.add('allow-annotation-bar-popover-overlay');
        }
      }
    }
  });

  // When document opens, set browser focus inside scrollable element so native scroll shortcuts work
  useEffect(() => {
    if (!openDocumentId) {
      return;
    }
    documentScrollableRootRef.current?.focus();
  }, [openDocumentId]);

  // Make sure the open document is always the focusedDocumentId
  useEffect(() => {
    if (openDocumentId && openDocumentId !== focusedDocumentId) {
      setFocusedDocumentId(openDocumentId, { userInteraction: null });
    }
  }, [openDocumentId, focusedDocumentId, setFocusedDocumentId]);

  // Fix focused doc id when item disappears. E.g when filtering by unseen
  useEffect(() => {
    if (!listedDocumentIds.length || !isDocumentListShown) {
      return;
    }

    const focusedIdIndex = listedDocumentIds.findIndex((docId) => docId === focusedDocumentId);
    focusedDocIndexRef.current = focusedIdIndex;

    if (focusedDocumentId && focusedIdIndex === -1) {
      if ((prevFocusedDocIndex !== 0 && !prevFocusedDocIndex) || prevFocusedDocIndex === -1) {
        return;
      }

      // At this point we have a focusedDocumentId that is not in the list anymore

      const possibleNextFocusedId = listedDocumentIds[prevFocusedDocIndex];

      if (possibleNextFocusedId) {
        setFocusedDocumentId(possibleNextFocusedId, {
          userInteraction: null,
        });
      } else {
        setFocusedDocumentId(listedDocumentIds[listedDocumentIds.length - 1], {
          userInteraction: null,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listedDocumentIds,
    focusedDocumentId,
    prevFocusedDocIndex,
    setFocusedDocumentId,
    openDocumentId,
    isDocumentListShown,
  ]);

  useEffect(() => {
    if (!listedDocumentIds.length || !isDocumentListShown) {
      return;
    }

    eventEmitter.emit('refocus-inbox-focus-indicator');
  }, [listedDocumentIds, isDocumentListShown]);

  // Support navigation, both of the openDocument and of the focusedDocument in the list
  // TODO: can we reconcile openDocument and focusedDocument to stay in sync without us having to manually do it
  //  (the openDocument should always automatically be set as focused)
  const navDocuments = useCallback(
    ({
      offset,
      fallbackToSameDocIfNoNextDoc = true,
      fallbackToNearbyIfNoNextDoc = false,
    }: {
      offset: number;
      fallbackToSameDocIfNoNextDoc?: boolean;
      fallbackToNearbyIfNoNextDoc?: boolean;
    }): void => {
      logger.debug('navDocuments', { focusedDocumentId });
      if (openDocumentId) {
        const newOpenDocumentId = getAdjacentDocumentId({
          currentDocumentId: openDocumentId,
          documentIds: listedDocumentIds,
          offset,
        });
        if (newOpenDocumentId) {
          history.push(urlJoin([documentPathPrefix, 'read', newOpenDocumentId]));
        }
        return;
      }

      let newOpenDocumentId = null;

      if (focusedDocumentId) {
        newOpenDocumentId = getAdjacentDocumentId({
          currentDocumentId: focusedDocumentId,
          documentIds: listedDocumentIds,
          offset,
          fallbackToNearbyIfNoNextDoc,
          fallbackToSameDocIfNoNextDoc,
        });
      }

      setFocusedDocumentId(newOpenDocumentId, {
        userInteraction: 'unknown',
      });
    },
    [
      documentPathPrefix,
      focusedDocumentId,
      history,
      listedDocumentIds,
      openDocumentId,
      setFocusedDocumentId,
    ],
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.GoToNextDocument],
    useCallback(() => navDocuments({ offset: +1 }), [navDocuments]),
  );
  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.GoToPreviousDocument],
    useCallback(() => navDocuments({ offset: -1 }), [navDocuments]),
  );

  const onKeyboardShortcutThatMovesDocuments = useCallback(
    async (
      focusedDocumentId: BaseDocument['id'] | null,
      actionFunction: (
        focusedDocumentId: BaseDocument['id'],
        options: ActionFunctionOptions,
      ) => Promise<{ userEvent?: UserEvent } | void>,
      shouldAnimate = true,
    ) => {
      if (!focusedDocumentId) {
        return;
      }

      const numListedDocuments = listedDocumentIds.length;

      const actionFunctionOptions: ActionFunctionOptions = {
        userInteraction: 'keypress',
      };

      if (isDocumentListShown && shouldAnimate) {
        actionFunctionOptions.beforeStateUpdate = async () => {
          setDocumentIdBeingRemoved(focusedDocumentId);
          await delay(200);
        };
      }

      const result = await actionFunction(focusedDocumentId, actionFunctionOptions);

      if (result) {
        if (numListedDocuments === 1) {
          // If we just moved the last doc in the list, unfocus it
          setFocusedDocumentId(null, {
            userInteraction: 'keypress',
          });

          if (!isDocumentListShown) {
            history.push(parentPath);
          }
        } else if (openDocumentId) {
          if (autoAdvance) {
            navDocuments({ offset: +1 });
          } else {
            history.push(parentPath);

            const newFocusedDocumentId = getAdjacentDocumentId({
              currentDocumentId: openDocumentId,
              documentIds: listedDocumentIds,
              offset: +1,
              fallbackToNearbyIfNoNextDoc: true,
            });

            setFocusedDocumentId(newFocusedDocumentId, {
              userInteraction: 'unknown',
            });
          }
        } else {
          // If 'e' is pressed while focused on the last item of many, we want the focus to move up one item
          navDocuments({
            offset: +1,
            fallbackToSameDocIfNoNextDoc: false,
            fallbackToNearbyIfNoNextDoc: true,
          });
        }
      }

      if (isDocumentListShown) {
        // Without the following, the UI will be broken after undo
        setDocumentIdBeingRemoved(null);
      }
    },
    [
      autoAdvance,
      history,
      isDocumentListShown,
      listedDocumentIds,
      navDocuments,
      openDocumentId,
      parentPath,
      setFocusedDocumentId,
    ],
  );

  const afterDeleteAction = () => {
    if (listedDocumentIds.length === 1) {
      // If we just moved the last doc in the list, unfocus it
      setFocusedDocumentId(null, { userInteraction: 'unknown' });
    } else {
      // If 'e' is pressed while focused on the last item of many, we want the focus to move up one item
      navDocuments({
        offset: +1,
        fallbackToSameDocIfNoNextDoc: false,
        fallbackToNearbyIfNoNextDoc: true,
      });

      if (isReaderViewUrl()) {
        history.push(parentPath);
      }
    }
  };
  const location = history.location as unknown as Location;
  const splitBy = getSplitByKeyOrSplittingByKeyFromLocation(location);
  const isSplitBySeen = splitBy === SplitByKey.Seen;

  const documentLocations = useDocumentLocations();

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Archive],
    useCallback(
      async () =>
        onKeyboardShortcutThatMovesDocuments(
          focusedDocumentId,
          async (documentId, options) => {
            return updateDocumentLocation(documentId, DocumentLocation.Archive, options);
          },
          !isSplitBySeen,
        ),
      [focusedDocumentId, isSplitBySeen, onKeyboardShortcutThatMovesDocuments],
    ),
    {
      description: `Move to ${getUIFriendlyNameForDocumentLocation(DocumentLocation.Archive)}`,
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Later],
    useCallback(
      async () =>
        onKeyboardShortcutThatMovesDocuments(
          focusedDocumentId,
          async (documentId, options) => {
            return updateDocumentLocation(documentId, DocumentLocation.Later, options);
          },
          !isSplitBySeen,
        ),
      [focusedDocumentId, isSplitBySeen, onKeyboardShortcutThatMovesDocuments],
    ),
    {
      description: `Move to ${getUIFriendlyNameForDocumentLocation(DocumentLocation.Later)}`,
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.New],
    useCallback(
      async () =>
        onKeyboardShortcutThatMovesDocuments(
          focusedDocumentId,
          async (documentId, options) => {
            if (!documentLocations.includes(DocumentLocation.New)) {
              return;
            }
            return updateDocumentLocation(documentId, DocumentLocation.New, options);
          },
          !isSplitBySeen,
        ),
      [documentLocations, focusedDocumentId, isSplitBySeen, onKeyboardShortcutThatMovesDocuments],
    ),
    {
      description: `Move to ${getUIFriendlyNameForDocumentLocation(DocumentLocation.New)}`,
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Shortlist],
    useCallback(
      async () =>
        onKeyboardShortcutThatMovesDocuments(
          focusedDocumentId,
          async (documentId, options) => {
            await addToShortlist(
              {
                id: documentId,
                isFeedView,
              },
              {
                ...options,
                userInteraction: 'keyup',
              },
            );
          },
          !isSplitBySeen,
        ),
      [focusedDocumentId, isFeedView, isSplitBySeen, onKeyboardShortcutThatMovesDocuments],
    ),
    {
      description: 'Add to shortlist',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.DeleteDocument],
    useCallback(
      async () =>
        onKeyboardShortcutThatMovesDocuments(focusedDocumentId, async (documentId, options) => {
          if (options.beforeStateUpdate) {
            await options.beforeStateUpdate();
          }
          return safeDelete(documentId, options);
        }),
      [focusedDocumentId, onKeyboardShortcutThatMovesDocuments],
    ),
    {
      description: 'Delete document',
    },
  );

  const [focusedDocumentForRSS] = usePartialDocument(focusedDocumentId, [
    'category',
    'source_specific_data',
    'url',
  ]);

  const { possibleRss, subscribed } = useMatchingRSS(focusedDocumentForRSS);
  const feedId = useRSSFeedIdByUrl(possibleRss?.url);

  const [currentDocument] = useDocument(focusedDocumentId);
  const documentCategoryIsEmail = currentDocument?.category === 'email';
  const emailAddress =
    currentDocument?.source_specific_data?.email?.author_email ||
    currentDocument?.source_specific_data?.email?.from_email ||
    '';
  const emailIsSubscribed = emailAddress
    ? allEmailSubscriptions[emailAddress]?.subscribed ?? false
    : false;

  const toggleSubscriptionMessage = useMemo(() => {
    return documentCategoryIsEmail
      ? `Subscribe/unsubscribe from ${emailAddress !== '' ? emailAddress : 'email address'}`
      : `Subscribe/unsubscribe to the document's RSS feed`;
  }, [documentCategoryIsEmail, emailAddress]);

  const emailSpecificWarningMessage = generateEmailWarningMessage(emailAddress);

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.ToggleRssOrEmailSubscription],
    useCallback(async () => {
      if (subscribed || (emailIsSubscribed && emailAddress)) {
        setDeleteFeedDialogOpen(true);
      } else if (documentCategoryIsEmail && !emailIsSubscribed && emailAddress) {
        await toggleEmailSubscriptionfromPalette(
          emailAddress,
          emailIsSubscribed,
          emailSpecificWarningMessage,
          { userInteraction: 'keypress' },
        );
      } else if (possibleRss && !subscribed) {
        await toggleDocumentFeedSubscription(
          possibleRss,
          subscribed,
          feedId,
          messageCopy.removeFeedWarningText,
          { userInteraction: 'keypress' },
        );
      }
      // We can't pass possibleRss as a dep because it causes an infinite re-render loop, so we just use the url
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      documentCategoryIsEmail,
      feedId,
      possibleRss?.url,
      subscribed,
      emailAddress,
      emailIsSubscribed,
      emailSpecificWarningMessage,
    ]),
    {
      description: toggleSubscriptionMessage,
      customId: 'toggle-rss-or-email',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Bump],
    useCallback(
      () =>
        onKeyboardShortcutThatMovesDocuments(focusedDocumentId, async (documentId, options) => {
          if (options.beforeStateUpdate) {
            await options.beforeStateUpdate();
          }
          return bumpDocumentForUser(documentId, options);
        }),
      [focusedDocumentId, onKeyboardShortcutThatMovesDocuments],
    ),
    {
      description: 'Bump document to top',
    },
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.ToggleDocAsOpened],
    useCallback(() => {
      if (!focusedDocumentId || openDocumentId) {
        return;
      }

      toggleDocumentOpened(focusedDocumentId, true);
      navDocuments({ offset: +1 });
    }, [focusedDocumentId, navDocuments, openDocumentId]),
    {
      description: 'Mark document as seen or unseen',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Esc],
    useCallback(
      (event) => {
        if (isQuoteshotModalOpen) {
          event.preventDefault();
          setQuoteshotModalOpen(false);
          return;
        }
        if (isCmdPaletteOpen) {
          event.preventDefault();
          setCmdPaletteOpen(false, { userInteraction: 'keypress' });
          return;
        }

        if (isDocumentsSortMenuShown) {
          event.preventDefault();
          setDocumentsSortMenuOpen(false, { userInteraction: 'keypress' });
          return;
        }

        if (isAppearancePanelShown) {
          event.preventDefault();
          setAppearancePanelOpen(false, { userInteraction: 'keypress' });
          return;
        }

        if (isDocumentMetadataShown) {
          return;
        }

        if (modal !== null && modal !== undefined) {
          event.preventDefault();
          hideModal({ id: modal }, { userInteraction: 'unknown' });
          return;
        }

        event.preventDefault();

        const isFeedList =
          (history.location.pathname.startsWith('/feed') ||
            history.location.pathname.startsWith('/new')) &&
          !history.location.pathname.includes('/read');

        if (isFeedList) {
          return;
        }

        if (history.location.pathname === parentPath) {
          // We're already at the parentPath, so we can't go back to it. So just go back to root.
          history.push('/');
        } else {
          history.push(parentPath, {
            didNavigateUsingBackShortcut: true,
          });
        }
      },
      [
        history,
        isQuoteshotModalOpen,
        isAppearancePanelShown,
        isCmdPaletteOpen,
        isDocumentMetadataShown,
        isDocumentsSortMenuShown,
        modal,
        parentPath,
      ],
    ),
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.AddToFavorites],
    useCallback(async () => {
      if (focusedDocumentId) {
        toggleTag(focusedDocumentId, 'favorite', { userInteraction: 'keyup' });
      }
    }, [focusedDocumentId]),
    {
      description: 'Add to favorites',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Tags],
    useCallback(
      async (event) => {
        if (!focusedDocumentId || openDocumentId) {
          return;
        }

        event.preventDefault();
        eventEmitter.emit('open-document-list-edit-tags-popover', focusedDocumentId);
      },
      [focusedDocumentId, openDocumentId],
    ),
    {
      description: 'Add / Edit tags for document',
      shouldShowInHelp: !openDocumentId,
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.OpenOriginalDoc],
    useCallback(() => {
      if (focusedDocumentId) {
        openOriginalDocument(focusedDocumentId);
      }
    }, [focusedDocumentId]),
    {
      description: 'View on web',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Share],
    useCallback(() => {
      if (focusedDocumentId) {
        copyDocumentUrl(focusedDocumentId);
      }
    }, [focusedDocumentId]),
    {
      description: 'Copy URL',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.ShareReaderLink],
    useCallback(() => {
      if (focusedDocumentId) {
        copyDocumentReaderUrl(focusedDocumentId);
      }
    }, [focusedDocumentId]),
    {
      description: 'Copy Reader URL',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.ShowDocMetadata],
    useCallback(
      (event) => {
        if (!focusedDocumentId && !openDocumentId) {
          return;
        }

        event.preventDefault();
        toggleDocumentMetadataOpen({ userInteraction: 'keypress' });
      },
      [focusedDocumentId, openDocumentId],
    ),
    {
      description: 'Edit metadata',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.DocGhostreader],
    useCallback(
      (event) => {
        if (!focusedDocumentId && !openDocumentId) {
          return;
        }

        event.preventDefault();
        openDocumentGptSubMenu();
      },
      [focusedDocumentId, openDocumentId],
    ),
    {
      description: 'Invoke Ghostreader',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.AddDocNote],
    useCallback(
      (event) => {
        if (!focusedDocumentId && !openDocumentId) {
          return;
        }

        event.preventDefault();
        focusDocumentNoteField('keyup');
      },
      [focusedDocumentId, openDocumentId],
    ),
    {
      description: 'Add a document note',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.ResetReadingProgress],
    useCallback(
      async (event) => {
        const docId = focusedDocumentId ?? openDocumentId;
        if (!docId) {
          return;
        }

        event.preventDefault();
        resetReadingProgress(docId);
      },
      [focusedDocumentId, openDocumentId],
    ),
    {
      description: 'Reset reading progress',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.EnableOrViewPublicLink],
    useCallback(
      async (event) => {
        if (!openDocumentId) {
          return;
        }

        event.preventDefault();
        enableDocumentShare({ docId: openDocumentId, userInteraction: 'keyup' });
        eventEmitter.emit('enable-public-link-shorcut-pressed');
      },
      [openDocumentId],
    ),
    {
      description: 'Enable / view public link',
    },
  );

  /*
    The following hooks only run when there is no open document
  */

  // Only override arrow keys behavior from the list view, to work the same as j/k:
  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Down],
    useCallback(
      (event) => {
        if (
          openDocumentId ||
          isDocumentMetadataShown ||
          document.getElementById('notebook-sidebar-panel')?.contains(document.activeElement)
        ) {
          return;
        }
        event.preventDefault();
        navDocuments({ offset: +1 });
      },
      [isDocumentMetadataShown, navDocuments, openDocumentId],
    ),
    {
      description: 'Navigate to previous document',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Up],
    useCallback(
      (event) => {
        if (
          openDocumentId ||
          isDocumentMetadataShown ||
          document.getElementById('notebook-sidebar-panel')?.contains(document.activeElement)
        ) {
          return;
        }
        event.preventDefault();
        navDocuments({ offset: -1 });
      },
      [isDocumentMetadataShown, navDocuments, openDocumentId],
    ),
    {
      description: 'Navigate to next document',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Enter],
    useCallback(() => {
      if (!focusedDocumentId || isDocumentMetadataShown || isDocMoreActionsDropdownOpen) {
        return;
      }
      history.push(urlJoin([documentPathPrefix, 'read', focusedDocumentId]));
    }, [
      documentPathPrefix,
      focusedDocumentId,
      history,
      isDocumentMetadataShown,
      isDocMoreActionsDropdownOpen,
    ]),
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.ExportDownloadAnnotations],
    useCallback(() => {
      if (focusedDocumentId) {
        exportHighlightsToFile(focusedDocumentId);
      }
    }, [focusedDocumentId]),
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.ExportCopyToClipboard],
    useCallback(() => {
      if (focusedDocumentId) {
        exportHighlightsToClipboard(focusedDocumentId);
      }
    }, [focusedDocumentId]),
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.ToggleDocMoreActions],
    useCallback(async () => {
      if (!isReaderViewUrl()) {
        toggleIsDocMoreActionsDropdownOpen();
      }
    }, []),
    {
      description: 'Toggle doc more actions dropdown',
    },
  );

  // tabIndex=-1 so we can focus it after a document so that native scroll shortcuts work
  return (
    <>
      <DocumentList
        className={styles.root}
        currentSortRule={currentSortRule}
        documentIdBeingRemoved={documentIdBeingRemoved}
        documentIds={listedDocumentIds}
        documentPathPrefix={documentPathPrefix}
        showSearchMatches={showSearchMatches}
        isShown={isDocumentListShown}
        parentPath={parentPath}
        onEndThresholdReached={onEndThresholdReached}
        pageSize={pageSize}
      />
      <div
        className={[
          styles.root,
          'has-visible-scrollbar',
          'allow-annotation-bar-popover-overlay',
          !openDocumentId ? styles.hiddenDocReader : '',
        ].join(' ')}
        id="document-reader-root"
        ref={documentScrollableRootRef}
        tabIndex={-1}
      >
        <DocumentReader
          docId={openDocumentId}
          documentPathPrefix={documentPathPrefix}
          inboxDocumentIds={listedDocumentIds}
          parentPath={parentPath}
          scrollableAncestorRef={documentScrollableRootRef as React.MutableRefObject<HTMLElement>}
          onEndThresholdReached={onEndThresholdReached}
        />
        <div className="tts-position-indicator tts-position-indicator-start" />
        <div className="tts-position-indicator tts-position-indicator-end" />
      </div>
      <DeleteDocumentDialog afterDeleteAction={afterDeleteAction} />
      {(possibleRss || (documentCategoryIsEmail && emailAddress)) &&
        (subscribed || emailIsSubscribed) && (
          <DeleteFeedDialog
            isOpen={deleteFeedDialogOpen}
            onConfirm={() => {
              setDeleteFeedDialogOpen(false);
              if (possibleRss && feedId) {
                removeFeed(feedId, { userInteraction: 'sidebar-button-click' });
              } else {
                toggleEmailSubscription(emailAddress, emailIsSubscribed, {
                  userInteraction: 'sidebar-button-click',
                });
                removeDocsFromTheSameEmailAddress(emailAddress);
              }
              leaveReadingViewIfOpen();
            }}
            onCancel={() => setDeleteFeedDialogOpen(false)}
            message={possibleRss ? messageCopy.removeFeedWarningText : emailSpecificWarningMessage}
          />
        )}
    </>
  );
});

// defaultExport.whyDidYouRender = {
//   customName: 'Docs',
//   logOnDifferentValues: true,
// };

export default defaultExport;
