import { Core as CoreType } from '@pdftron/webviewer';
import { EventEmitter2 } from 'eventemitter2';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { ulid } from 'ulid';

import { openHighlightGptSubMenu } from '../../../shared/foreground/cmdPalette';
import {
  useHighlightNoteTextsMap,
  useHighlights,
  useHighlightsMap,
} from '../../../shared/foreground/database/helperHooks';
import foregroundEventEmitter from '../../../shared/foreground/eventEmitter';
import { downloadFile, loadDocument } from '../../../shared/foreground/file.platform';
import { ForegroundEventName, globalState, updateState } from '../../../shared/foreground/models';
import { usePDFAnnotationSync } from '../../../shared/foreground/pdfHooks';
import { getDocument } from '../../../shared/foreground/stateGetters';
import { usePersistentPdfSettings } from '../../../shared/foreground/stateHooks';
import {
  updateReadingPosition,
  updateScrollPosition,
} from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/progressRelated';
import { setFocusedHighlightId } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { createToast } from '../../../shared/foreground/toasts.platform';
import type { HighlightElement } from '../../../shared/foreground/types';
import createElementFromString from '../../../shared/foreground/utils/createElementFromString';
import isComposing from '../../../shared/foreground/utils/isComposing';
import Renderer from '../../../shared/foreground/utils/Renderer';
import { upsertPDFHighlightImage } from '../../../shared/foreground/utils/uploadFiles';
import {
  Category,
  ContentParsingStatus,
  ContentRequestLoadingStatus,
  DisplayTheme,
  DocumentWithTransientData,
  FirstClassDocument,
  Highlight,
  Pdf,
} from '../../../shared/types';
import { ShortcutId } from '../../../shared/types/keyboardShortcuts';
import nowTimestamp from '../../../shared/utils/dates/nowTimestamp';
import delay from '../../../shared/utils/delay';
import { pdftronAssetsDirectoryName } from '../../../shared/utils/environment';
import getDocumentTitle from '../../../shared/utils/getDocumentTitle';
import makeLogger from '../../../shared/utils/makeLogger';
import { AdaptiveHeaderContext } from '../contexts';
import { useKeyboardShortcut, useKeyboardShortcutPreventDefault } from '../hooks/useKeyboardShortcut';
import { useShortcutsMap } from '../utils/shortcuts';
import { baseShortcuts } from '../utils/shortcuts/defaultsByLayout';
import Button from './Button';
import styles from './DocumentTextContent.module.css';
import ChevronDownIcon from './icons/ChevronDownIcon';
import ChevronUpIcon from './icons/ChevronUpIcon';
import SearchNavIcon from './icons/SearchNavIcon';
import StrokeCancelIcon from './icons/StrokeCancelIcon';
import { openSingleParentNotebookView } from './NotebookView/notebookHelpers';
import PDFPopovers from './PDFPopovers';
import ReadingProgressBar from './ReadingProgressBar';
import { ReturnToReadingButton } from './ReturnToReadingButton';

// TODO:
// - Fix when moving snapshot diff page
// - Fix highlight not found after delete error

const yellow60Rgb = { r: 193, g: 157, b: 24 };
const yellow70Rgb = { r: 255, g: 191, b: 12 };
const HighlightToolName = 'AnnotationCreateTextHighlight'; // window.Core.Tools.ToolNames.HIGHLIGHT
const RectangleToolName = 'AnnotationCreateRectangle'; // window.Core.Tools.ToolNames.RECTANGLE
const logger = makeLogger(__filename);

const useSnapshotShortcut = () => {
  const currentToolName = useRef(HighlightToolName);
  const { documentViewer } = useContext(PdfTronContext);
  const shortcutsMap = useShortcutsMap();

  const onSnapshotShortcutKeydown = useCallback(async () => {
    if (!documentViewer || currentToolName.current === RectangleToolName) {
      return;
    }

    documentViewer.setToolMode(documentViewer.getTool(RectangleToolName));
  }, [documentViewer]);

  const debouncedOnSnapshotShortcutKeyDown = useMemo(
    () =>
      debounce(onSnapshotShortcutKeydown, 300, {
        leading: true,
        trailing: false,
      }),
    [onSnapshotShortcutKeydown],
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.PdfSnapshot],
    debouncedOnSnapshotShortcutKeyDown,
    {
      description: 'Select area in PDF',
      preferredEventTrigger: 'keydown',
    },
  );

  useEffect(() => {
    if (!documentViewer) {
      return;
    }

    const onToolUpdated = (newToolObject: CoreType.Tools.Tool) => {
      if (newToolObject.name && newToolObject.name !== currentToolName.current) {
        currentToolName.current = newToolObject.name;
      }
    };

    documentViewer.addEventListener('toolModeUpdated', onToolUpdated);

    return () => {
      documentViewer.removeEventListener('toolModeUpdated', onToolUpdated);
    };
  }, [documentViewer]);
};

interface PdfTronContextState {
  documentViewer?: CoreType.DocumentViewer;
  setDocumentViewer: React.Dispatch<React.SetStateAction<CoreType.DocumentViewer | undefined>>;
  documentLoaded: boolean;
  setDocumentLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PdfTronContext = createContext({} as PdfTronContextState);

const PDFLoader = () => (
  <div className={styles.previewAnimation}>
    <div className={styles.loadPDF}>
      <p>
        <u />
        <u />
        <u />
        <u />
      </p>
      <p>
        <u />
        <u />
        <u />
        <u />
      </p>
    </div>
  </div>
);

interface PDFContentManagerProps {
  docId: string;
  parsedDocId: Pdf['parsed_doc_id'];
  currentScrollPosition: FirstClassDocument['currentScrollPosition'] | undefined;
  documentLoaded: boolean;
  setDocumentLoaded: (loaded: boolean) => void;
  annotationManager: CoreType.AnnotationManager | undefined;
  documentViewer: CoreType.DocumentViewer | undefined;
  setHighlightElements: (elements: (oldElems: HighlightElement[]) => HighlightElement[]) => void;
  eventEmitter: EventEmitter2;
  createBoundingElementForHighlight: (
    annotation: CoreType.Annotations.Annotation,
    rwHighlight?: Highlight,
  ) => HighlightElement | undefined;
  setPdfBookmarks: (bookmarks: CoreType.Bookmark[]) => void;
  removeHtmlHighlight: (pageNumber: number, id: Highlight['id']) => void;
  openSearchBar: () => void;
  docTitle: string;
}

const licenceKey =
  'Readwise Inc.:OEM:Readwise Reader::B+:AMS(20241208):8B575A131FF78AD0D33352184F716F2F22929C05DB3469C2876440A642B231F5C7';
type PDFSearchResult = CoreType.Search.SearchResult;

const PDFSearchBar = ({
  documentViewer,
  onClose,
}: { documentViewer: CoreType.DocumentViewer | undefined; onClose: () => void }) => {
  const [currentResults, setCurrentResults] = useState<PDFSearchResult[]>([]);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentResultIndex, setCurrentResultIndex] = useState(-1);

  useEffect(() => {
    return () => {
      documentViewer?.clearSearchResults();
    };
  }, [documentViewer]);

  const searchDebounceTimer = useRef(0);

  const onSearchInProgress = useCallback((inProgress: boolean) => {
    setSearchInProgress(inProgress);
  }, []);

  const clearResults = useCallback(() => {
    documentViewer?.clearSearchResults();
    setSearchValue('');
    setCurrentResultIndex(-1);
    setCurrentResults([]);
  }, [documentViewer]);

  useEffect(() => {
    if (!documentViewer) {
      return;
    }
    documentViewer.addEventListener('searchInProgress', onSearchInProgress);

    return () => {
      documentViewer.removeEventListener('searchInProgress', onSearchInProgress);
    };
  }, [onSearchInProgress, documentViewer]);

  useEffect(() => {
    if (!documentViewer) {
      return;
    }
    const Core = window.Core;
    const mode = Core.Search.Mode.PAGE_STOP | Core.Search.Mode.HIGHLIGHT;
    const searchOptions = {
      // If true, a search of the entire document will be performed. Otherwise, a single search will be performed.
      fullSearch: true,
      // The callback function that is called when the search returns a result.
      onResult: (result: PDFSearchResult) => {
        setCurrentResults((prevResults) => [...prevResults, result]);
      },
    };
    if (searchDebounceTimer.current) {
      clearTimeout(searchDebounceTimer.current);
    }

    if (!searchValue) {
      setCurrentResults([]);
      setCurrentResultIndex(-1);
      return;
    }

    searchDebounceTimer.current = window.setTimeout(() => {
      setCurrentResults([]);
      setCurrentResultIndex(-1);
      documentViewer.textSearchInit(searchValue, mode, searchOptions);
    }, 400);
  }, [documentViewer, searchValue]);

  useEffect(() => {
    if (searchInProgress || !documentViewer || !currentResults) {
      return;
    }
    const currentPage = documentViewer.getCurrentPage();
    let closestPageDistance = Infinity;
    let closestIndex = 0;
    for (let i = 0; i < currentResults.length; i++) {
      const result = currentResults[i];
      // If there is a result on the current page, focus that result first
      if (result.pageNum === currentPage) {
        setCurrentResultIndex(i);
        return;
      }
      // else, lets find the closest result and focus that
      const prevClosestDistance = Math.abs(result.pageNum - currentPage);
      if (closestPageDistance < prevClosestDistance) {
        closestPageDistance = prevClosestDistance;
        closestIndex = i;
      }
    }
    setCurrentResultIndex(closestIndex);
  }, [currentResults, documentViewer, searchInProgress]);
  useEffect(() => {
    if (
      !searchInProgress &&
      documentViewer &&
      currentResultIndex < currentResults.length &&
      currentResultIndex >= 0
    ) {
      try {
        documentViewer.displaySearchResult(currentResults[currentResultIndex]);
      } catch (e) {
        logger.error('error displaying search result', { e });
      }
    }
  }, [currentResultIndex, currentResults, documentViewer, searchInProgress]);

  const searchUp = useCallback(() => {
    if (currentResultIndex <= 0) {
      setCurrentResultIndex(currentResults.length - 1);
      return;
    }
    setCurrentResultIndex((prevIndex) => Math.max(0, prevIndex - 1));
  }, [currentResultIndex, currentResults.length]);

  const searchDown = useCallback(() => {
    if (currentResultIndex === currentResults.length - 1) {
      setCurrentResultIndex(0);
      return;
    }
    setCurrentResultIndex((prevIndex) => Math.min(currentResults.length - 1, prevIndex + 1));
  }, [currentResultIndex, currentResults.length]);

  return (
    <div className={styles.documentSearchBarWrapper}>
      <div className={styles.searchInputWrapper}>
        <div className={styles.searchIcon}>
          <SearchNavIcon />
        </div>
        <input
          aria-labelledby="pdf-search-label"
          autoFocus
          autoComplete="off"
          className={styles.searchInput}
          placeholder="Find in document..."
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (isComposing(event)) {
              return;
            }
            if (event.key === 'Escape' || event.keyCode === 27) {
              onClose();
            }
            if (event.key === 'Enter' || event.keyCode === 40) {
              if (event.shiftKey) {
                searchUp();
              } else {
                searchDown();
              }
            }
          }}
          value={searchValue}
          onChange={(event) => {
            setSearchValue(event.target.value);
          }}
        />
        <div className={styles.searchInputInfoContainer}>
          {currentResults.length > 0 ? (
            <span>{`${Math.min(currentResultIndex + 1, currentResults.length)} / ${
              currentResults.length
            }`}</span>
          ) : (
            <span>No matches</span>
          )}
          <Button onClick={clearResults} className={styles.searchButton}>
            <StrokeCancelIcon text="Clear" className={styles.searchButtonIcon} />
          </Button>
        </div>
      </div>
      <div className={styles.searchNavActions}>
        <Button onClick={searchUp} className={styles.searchButton}>
          <ChevronUpIcon text="Previous result" className={styles.searchButtonIcon} />
        </Button>
        <Button onClick={searchDown} className={styles.searchButton}>
          <ChevronDownIcon text="Next result" className={styles.searchButtonIcon} />
        </Button>
      </div>
      <div className={styles.searchDoneAction}>
        <Button onClick={onClose} className={styles.searchDoneButton}>
          <span>Done</span>
        </Button>
      </div>
    </div>
  );
};

const PDFContentManager = ({
  docId,
  parsedDocId,
  currentScrollPosition,
  documentViewer,
  annotationManager,
  documentLoaded,
  setDocumentLoaded,
  eventEmitter,
  setHighlightElements,
  createBoundingElementForHighlight,
  removeHtmlHighlight,
  setPdfBookmarks,
  openSearchBar,
  docTitle,
}: PDFContentManagerProps) => {
  const shortcutsMap = useShortcutsMap();

  const highlights = useHighlights({ parentDocId: docId });

  const highlightMap = useHighlightsMap(docId);
  const highlightNotesMap = useHighlightNoteTextsMap(highlights);

  const highlightIdToOpenAt = globalState(useCallback((state) => state.highlightIdToOpenAt, []));
  const [initialNavToHighlightDone, setInitialNavToHighlightDone] = useState(false);

  const pdfSettings = usePersistentPdfSettings(docId);

  const history = useHistory();

  const { setHeaderIsHidden } = useContext(AdaptiveHeaderContext);

  const importAnnotations = useCallback(
    (data: string) => {
      if (!annotationManager) {
        return Promise.reject();
      }
      return annotationManager.importAnnotations(data);
    },
    [annotationManager],
  );

  const deleteAnnotations = useCallback(
    (annots: { id: string; pageNumber: number }[]) => {
      if (!annotationManager) {
        return Promise.reject();
      }
      const annotations = annots.map(({ id }) => annotationManager.getAnnotationById(id));
      for (const annotation of annotations) {
        if (!annotation) {
          continue;
        }
        const readwiseId = annotation.getCustomData('readwise_id');
        const pageNumber = annotation.getPageNumber();
        removeHtmlHighlight(pageNumber, readwiseId);
      }
      annotationManager.deleteAnnotations(annotations, { source: 'readwise' });
      return Promise.resolve();
    },
    [annotationManager, removeHtmlHighlight],
  );

  useEffect(() => {
    if (!documentLoaded) {
      return;
    }
    // Check the highlights and see if we need to update note/tag icons
    for (const highlight of highlights) {
      const highlightElement = document.getElementById(`annot-wrapper-${highlight.id}`);
      if (!highlightElement) {
        continue;
      }
      const hasNotes = Boolean(highlight.children.length);
      const hasTags = Boolean(Object.keys(highlight.tags ?? {}).length);
      highlightElement.classList.toggle('rw-highlight--has-note', hasNotes);
      highlightElement.classList.toggle('rw-highlight--has-tag', hasTags);
    }
  }, [documentLoaded, highlights]);

  const goToAnnotation = useCallback(
    (highlightIdToOpenAt: Highlight['id'] | null) => {
      if (!annotationManager) {
        return;
      }
      const pdfHighlights = annotationManager.getAnnotationsList();
      if (!pdfHighlights) {
        return;
      }
      for (const pdfHighlight of pdfHighlights) {
        const id = pdfHighlight.getCustomData('readwise_id');
        if (!id) {
          continue;
        }
        if (id === highlightIdToOpenAt) {
          annotationManager.jumpToAnnotation(pdfHighlight);
          break;
        }
      }
    },
    [annotationManager],
  );

  useEffect(() => {
    goToAnnotation(highlightIdToOpenAt);
  }, [goToAnnotation, highlightIdToOpenAt]);

  const isDarkMode = globalState(
    useCallback((state) => state.webEffectiveTheme === DisplayTheme.Dark, []),
  );
  const selectedRectangleOutlineColor = useMemo(
    () =>
      isDarkMode
        ? new window.Core.Annotations.Color(yellow70Rgb.r, yellow70Rgb.g, yellow70Rgb.b)
        : new window.Core.Annotations.Color(yellow60Rgb.r, yellow60Rgb.g, yellow60Rgb.b),
    [isDarkMode],
  );

  const setSelectedRectangleAnnotationStyles = useCallback(
    (annotation: CoreType.Annotations.RectangleAnnotation) => {
      annotation.disableRotationControl();
      annotation.FillColor = new window.Core.Annotations.Color(0, 0, 0, 0);
      annotation.StrokeColor = selectedRectangleOutlineColor;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      annotation.StrokeWidth = 2;
    },
    [selectedRectangleOutlineColor],
  );

  const onInitialHighlightForNavLoaded = useCallback(
    (highlightId: Highlight['id']) => {
      if (initialNavToHighlightDone) {
        return;
      }
      goToAnnotation(highlightId);
      setInitialNavToHighlightDone(true);
    },
    [goToAnnotation, initialNavToHighlightDone],
  );

  const { drawHighlightsOnLoad, onAnnotationCreated, onAnnotationRemoved, onAnnotationUpdated } =
    usePDFAnnotationSync({
      importAnnotations,
      deleteAnnotations,
      highlights,
      initialHighlightForNavId: highlightIdToOpenAt,
      onInitialHighlightForNavLoaded,
    });

  const onAnnotationsDrawn = useCallback(
    (pageNum: number) => {
      if (!annotationManager) {
        return;
      }
      const annotElements: HighlightElement[] = [];
      const annotations = annotationManager.getAnnotationsList();

      for (const annotation of annotations) {
        if (annotation.ToolName === RectangleToolName) {
          setSelectedRectangleAnnotationStyles(annotation as CoreType.Annotations.RectangleAnnotation);
        }

        // TODO: Remove this once PDFTron fixes chrome highlight bug
        // annotation.Color = new Core.Annotations.Color(255, 248, 195, 0.4);
        if (pageNum !== annotation.getPageNumber()) {
          continue;
        }
        const id = annotation.getCustomData('readwise_id');
        if (!id) {
          continue;
        }
        const rwHighlight = highlightMap[id];
        const elem = createBoundingElementForHighlight(annotation, rwHighlight);
        if (!elem) {
          continue;
        }
        annotElements.push(elem);
      }

      if (!annotElements) {
        return;
      }
      setHighlightElements((oldElems: HighlightElement[]) => {
        const newElems = oldElems.filter((elem) => elem.dataset.page !== pageNum.toString());
        return [...newElems, ...annotElements];
      });
    },
    [
      annotationManager,
      setSelectedRectangleAnnotationStyles,
      createBoundingElementForHighlight,
      setHighlightElements,
      highlightMap,
    ],
  );

  const setRotation = useCallback(
    (rotationInDegrees: number) => {
      if (!documentViewer) {
        return;
      }

      let pdfTronRotationEnum = window.Core.PageRotation.E_0;

      if (rotationInDegrees === 90) {
        pdfTronRotationEnum = window.Core.PageRotation.E_90;
      } else if (rotationInDegrees === 180) {
        pdfTronRotationEnum = window.Core.PageRotation.E_180;
      } else if (rotationInDegrees === 270) {
        pdfTronRotationEnum = window.Core.PageRotation.E_270;
      }

      documentViewer.setRotation(pdfTronRotationEnum);
    },
    [documentViewer],
  );

  useEffect(() => {
    if (!documentViewer || !documentLoaded) {
      return;
    }
    if (pdfSettings?.rotation !== undefined) {
      setRotation(pdfSettings.rotation);
    }
  }, [documentLoaded, setRotation, documentViewer, pdfSettings?.rotation]);

  useEffect(() => {
    if (!documentViewer || !documentLoaded) {
      return;
    }
    if (pdfSettings?.zoom) {
      documentViewer.zoomTo(pdfSettings.zoom);
    }
  }, [documentLoaded, documentViewer, pdfSettings?.zoom]);

  const onDownload = useCallback(async () => {
    if (!documentViewer || !annotationManager) {
      return;
    }

    const doc = documentViewer.getDocument();

    const annotationsListWithContent: CoreType.Annotations.Annotation[] = [];
    const annotationsList = await annotationManager.getAnnotationsList();
    annotationsList.forEach((annotation) => {
      const highlightId = annotation.getCustomData('readwise_id');
      const note = highlightNotesMap[highlightId];
      annotation.setContents(note);
      annotationsListWithContent.push(annotation);
    });

    const xfdfString = await annotationManager.exportAnnotations({
      annotationList: annotationsListWithContent,
    });
    const data = await doc.getFileData({ xfdfString });
    const arr = new Uint8Array(data);
    downloadFile(docTitle, arr, 'application/pdf');
    createToast({ content: 'PDF downloaded', category: 'success' });
  }, [documentViewer, annotationManager, docTitle, highlightNotesMap]);

  useEffect(() => {
    foregroundEventEmitter.on(ForegroundEventName.DownloadPdf, onDownload);

    return () => {
      foregroundEventEmitter.off(ForegroundEventName.DownloadPdf, onDownload);
    };
  }, [onDownload]);

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.DownloadDocument],
    useCallback(onDownload, [onDownload]),
    { description: 'Download document with annotations' },
  );

  useKeyboardShortcutPreventDefault(shortcutsMap[ShortcutId.PdfFind], openSearchBar, {
    description: 'Find in document',
  });

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.ToggleNotebookView],
    useCallback(() => {
      if (highlights.length === 0) {
        return;
      }
      openSingleParentNotebookView(history, docId);
    }, [highlights, docId, history]),
  );

  useEffect(() => {
    const gpt = async (highlightId: string) => {
      const highlight = await getDocument<Highlight>(highlightId);
      if (!highlight) {
        throw new Error('no highlight');
      }
      await setFocusedHighlightId(highlightId);
      await updateState(
        (state) => {
          state.gptPrompt = {
            prompt: '',
            selection: highlight.markdown,
            expandedSelection: highlight.markdown,
            surroundingParagraphContents: '',
          };
        },
        { eventName: 'cmd-palette-gpt-opening', userInteraction: 'keyup' },
      );

      openHighlightGptSubMenu();
    };
    foregroundEventEmitter.on('gpt:open', gpt);

    return () => {
      foregroundEventEmitter.off('gpt:open', gpt);
    };
  }, []);

  // Load the document
  useEffect(() => {
    setDocumentLoaded(false);
    if (!documentViewer || !parsedDocId) {
      return;
    }
    const loadDoc = async () => {
      if (!parsedDocId) {
        return;
      }
      const fileBlob = await loadDocument(parsedDocId);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      documentViewer.loadDocument(fileBlob, { licenseKey: licenceKey, extension: 'pdf' });
      documentViewer.setTextHighlightColor('rgba(0,114,255,0.30)');
    };
    loadDoc();
  }, [documentViewer, parsedDocId, setDocumentLoaded]);

  const onDocumentLoaded = useCallback(async () => {
    if (!documentViewer) {
      return;
    }

    setDocumentLoaded(true);

    await delay(100);

    drawHighlightsOnLoad();
    eventEmitter.emit('content-frame:initialized');

    if (currentScrollPosition?.pageNumber && !highlightIdToOpenAt) {
      const currentScollPositionPageNumber = currentScrollPosition.pageNumber;
      if (currentScollPositionPageNumber > 1) {
        documentViewer.setCurrentPage(currentScollPositionPageNumber, false);
      }
      setInitialNavToHighlightDone(true);
      if (setHeaderIsHidden) {
        setTimeout(() => {
          setHeaderIsHidden(false);
        }, 0);
      }
    } else if (highlightIdToOpenAt) {
      goToAnnotation(highlightIdToOpenAt);
    }

    if (pdfSettings && pdfSettings.zoom) {
      documentViewer.zoomTo(pdfSettings.zoom);
    }
    if (pdfSettings && pdfSettings.rotation) {
      setRotation(pdfSettings.rotation);
    }
    const doc = documentViewer.getDocument();

    if (!doc) {
      return;
    }

    doc
      .getBookmarks()
      .then((bookmarks) => {
        setPdfBookmarks(bookmarks);
      })
      .catch((error) => {
        logger.error(error);
      });
  }, [
    documentViewer,
    setRotation,
    setHeaderIsHidden,
    currentScrollPosition,
    setDocumentLoaded,
    drawHighlightsOnLoad,
    eventEmitter,
    highlightIdToOpenAt,
    pdfSettings,
    goToAnnotation,
    setPdfBookmarks,
  ]);

  const onPageNumberChanged = useCallback(
    (currentPageNum: number) => {
      if (!documentViewer || !docId || !documentLoaded) {
        return;
      }

      const pageCount = documentViewer.getPageCount();
      updateScrollPosition(
        docId,
        {
          scrollDepth: currentPageNum / pageCount,
          serializedPosition: null,
          pageNumber: currentPageNum,
        },
        {
          eventName: 'document-scroll-position-updated',
          userInteraction: 'scroll',
          isUndoable: false,
        },
      );
      updateReadingPosition(
        docId,
        {
          scrollDepth: currentPageNum / pageCount,
          serializedPosition: null,
          pageNumber: currentPageNum,
        },
        {
          eventName: 'document-progress-position-updated',
          userInteraction: 'scroll',
          isUndoable: false,
        },
      );
    },
    [docId, documentViewer, documentLoaded],
  );

  const debouncedOnPageNumberChanged = useMemo(
    () => debounce(onPageNumberChanged, 300),
    [onPageNumberChanged],
  );

  const getRectBase64 = useCallback(
    (annotation: CoreType.Annotations.Annotation): Promise<string | undefined> => {
      if (!documentViewer || annotation.ToolName !== RectangleToolName) {
        return Promise.resolve(undefined);
      }

      return new Promise((resolve) => {
        const cropRect = annotation.getRect();
        const doc = documentViewer.getDocument();

        doc.loadCanvas({
          pageNumber: annotation.PageNumber,
          renderRect: cropRect,
          drawComplete: async (canvas) => {
            const base64data = await canvas.toDataURL();
            resolve(base64data);
          },
        });
      });
    },
    [documentViewer],
  );

  const onAnnotationChanged = useCallback(
    async (
      annotations: CoreType.Annotations.Annotation[],
      action: string,
      { imported }: { imported: boolean },
    ) => {
      if (!annotationManager || !documentViewer || imported) {
        return;
      }

      for (const annotation of annotations) {
        let readerFileId;
        let imgUrl;

        if (action === 'add') {
          const pageNums = new Set<number>();

          // Get the text from highlight
          const { Id } = annotation;
          const text = annotation.getCustomData('trn-annot-preview');
          const isRectangleAnnotation = annotation.ToolName === RectangleToolName;

          if (!text && !isRectangleAnnotation) {
            return;
          }

          const highlightId = ulid().toLowerCase();
          const pageNumber = annotation.getPageNumber();
          pageNums.add(pageNumber);
          annotation.setCustomData('readwise_id', highlightId);

          if (isRectangleAnnotation) {
            const base64Img = await getRectBase64(annotation);

            if (!base64Img) {
              annotationManager.deleteAnnotation(annotation, { source: 'readwise-error' });
              createToast({
                content: 'Failed to create highlight image. Please try again.',
                category: 'error',
              });
              return;
            }

            const response = await upsertPDFHighlightImage({
              highlightId,
              base64Img,
            });

            if (response) {
              readerFileId = response.reader_file_id;
              imgUrl = response.image_url;
            }
          }

          try {
            const annotData = await annotationManager.exportAnnotations({
              annotationList: [annotation],
            });
            const parser = new DOMParser();
            const pdfData = parser.parseFromString(annotData, 'text/xml');
            // The XML structure for a text highlight looks like this:
            // <highlight rect="..."
            // and when it's a rectangle annotation it looks like this:
            // <square rect="..."
            const tagName = isRectangleAnnotation ? 'square' : 'highlight';
            const highlights = pdfData.getElementsByTagName(tagName);
            let offset = 0;

            if (highlights.length > 0) {
              // This really should never happen but being cautious
              const highlight = highlights[0];
              const rect = highlight.getAttribute('rect') || '0,0,0,0';
              offset = parseInt(rect.split(',')[1], 10);
            }

            await onAnnotationCreated({
              highlightText: text,
              id: highlightId,
              parentId: docId,
              pdfData: annotData,
              pdfId: Id,
              pageNumber,
              offset: parseInt(`${pageNumber}${1000000 - offset}`, 10),
              readerFileId,
              imgUrl,
            });

            if (isRectangleAnnotation) {
              documentViewer.setToolMode(documentViewer.getTool(HighlightToolName));
              annotationManager.selectAnnotation(annotation);
            }
          } catch (e) {
            logger.error('error creating highlight', { e });
            // Something failed, delete this annotation to not give false sense of security to user
            annotationManager.deleteAnnotation(annotation, { source: 'readwise-error' });
            createToast({
              content: 'Failed to create highlight. Please try again.',
              category: 'error',
            });
          }

          for (const pageNum of Array.from(pageNums)) {
            onAnnotationsDrawn(pageNum);
          }
        }

        if (action === 'modify') {
          if (annotation.ToolName !== RectangleToolName) {
            return;
          }

          const readwiseId = annotation.getCustomData('readwise_id');
          const highlight = await getDocument(readwiseId);

          if (!highlight) {
            return;
          }

          const annotData = await annotationManager.exportAnnotations({ annotationList: [annotation] });

          const { Id } = annotation;
          const pageNumber = annotation.getPageNumber();
          const oldData = highlight.source_specific_data ?? {};

          const base64Img = await getRectBase64(annotation);

          if (!base64Img) {
            createToast({
              content: 'Failed to update highlight image. Please try again.',
              category: 'error',
            });
            return;
          }

          const response = await upsertPDFHighlightImage({
            highlightId: readwiseId,
            base64Img,
            isUpdate: true,
          });

          if (response) {
            imgUrl = response.image_url;
          }

          const dateNow = nowTimestamp();

          const updates = {
            html: `<img src="${imgUrl}?t=${dateNow}"/>`,
            markdown: `![](${imgUrl})?t=${dateNow}`,
            source_specific_data: {
              ...oldData,
              pdf_highlight: {
                ...(oldData.pdf_highlight ?? {}),
                id: Id,
                data: annotData,
                page: pageNumber,
              },
            },
          };

          onAnnotationUpdated({ readwiseId, updates });

          // Remove old highlight and re-calculate new one
          const pageElem = document.getElementById(`pageContainer${pageNumber}`);

          if (!pageElem) {
            return;
          }

          const oldWrapper = pageElem.querySelector(`#annot-wrapper-${readwiseId}`);

          if (oldWrapper) {
            oldWrapper.remove();
          }

          onAnnotationsDrawn(pageNumber);
        }

        if (action === 'delete') {
          const readwiseId = annotation.getCustomData('readwise_id');
          const pageNumber = annotation.getPageNumber();
          removeHtmlHighlight(pageNumber, readwiseId);
          const { Id } = annotation;
          if (!readwiseId) {
            return;
          }
          onAnnotationRemoved({ page: pageNumber, pdfId: Id, readwiseId });
        }
      }
    },
    [
      annotationManager,
      documentViewer,
      onAnnotationUpdated,
      onAnnotationCreated,
      docId,
      onAnnotationsDrawn,
      removeHtmlHighlight,
      onAnnotationRemoved,
      getRectBase64,
    ],
  );

  useEffect(() => {
    if (!annotationManager) {
      return;
    }
    annotationManager.addEventListener('annotationChanged', onAnnotationChanged);

    return () => {
      annotationManager.removeEventListener('annotationChanged', onAnnotationChanged);
    };
  }, [onAnnotationChanged, annotationManager]);

  useEffect(() => {
    if (!documentViewer) {
      return;
    }
    documentViewer.addEventListener('documentLoaded', onDocumentLoaded);

    return () => {
      documentViewer.removeEventListener('documentLoaded', onDocumentLoaded);
    };
  }, [onDocumentLoaded, documentViewer]);

  useEffect(() => {
    if (!documentViewer || !documentLoaded) {
      return;
    }
    documentViewer.addEventListener('pageNumberUpdated', debouncedOnPageNumberChanged);

    return () => {
      documentViewer.removeEventListener('pageNumberUpdated', debouncedOnPageNumberChanged);
    };
  }, [debouncedOnPageNumberChanged, documentViewer, documentLoaded]);

  const onAnnotationSelected = useCallback(
    (annotations: CoreType.Annotations.Annotation[]) => {
      for (const annotation of annotations) {
        const id = annotation.getCustomData('readwise_id');
        if (!id) {
          continue;
        }

        if (annotation.ToolName === RectangleToolName) {
          setSelectedRectangleAnnotationStyles(annotation as CoreType.Annotations.RectangleAnnotation);
        }

        eventEmitter.emit('content-frame:click', { id });
      }

      const annotationsToDeselect = annotations.filter(
        (annotation) => annotation.ToolName !== RectangleToolName,
      );

      if (annotationManager && annotationsToDeselect.length > 0) {
        annotationManager.deselectAnnotations(annotationsToDeselect);
      }
    },
    [annotationManager, setSelectedRectangleAnnotationStyles, eventEmitter],
  );

  useEffect(() => {
    if (!annotationManager) {
      return;
    }
    annotationManager.addEventListener('annotationSelected', onAnnotationSelected);

    return () => {
      annotationManager.removeEventListener('annotationSelected', onAnnotationSelected);
    };
  }, [annotationManager, onAnnotationSelected]);

  const onAnnotationDeselected = useCallback(
    (annotations: CoreType.Annotations.Annotation[]) => {
      for (const annotation of annotations) {
        if (annotation.ToolName !== RectangleToolName) {
          return;
        }

        const id = annotation.getCustomData('readwise_id');

        // TODO: figure it out why onAnnotationSelected fires after Deselecting an annotation
        setTimeout(() => {
          eventEmitter.emit(`annotationPopover-${id}:hide`);
        }, 100);
      }
    },
    [eventEmitter],
  );

  useEffect(() => {
    if (!annotationManager) {
      return;
    }
    annotationManager.addEventListener('annotationDeselected', onAnnotationDeselected);

    return () => {
      annotationManager.removeEventListener('annotationDeselected', onAnnotationDeselected);
    };
  }, [annotationManager, onAnnotationDeselected]);

  useEffect(() => {
    if (!documentViewer) {
      return;
    }
    documentViewer.addEventListener('pageComplete', onAnnotationsDrawn);

    return () => {
      documentViewer.removeEventListener('pageComplete', onAnnotationsDrawn);
    };
  }, [onAnnotationsDrawn, documentViewer]);

  return null;
};

const limitCalls = (callback: () => void) => debounce(callback, 150);

interface PDFViewerProps {
  currentDoc?: DocumentWithTransientData<Pdf> | null | undefined;
  currentScrollPosition?: FirstClassDocument['currentScrollPosition'];
  customPage?: number;
  docId?: string;
  isPDF: boolean;
  parsedDocId?: Pdf['parsed_doc_id'];
  readingPosition?: FirstClassDocument['readingPosition'];
  scrollableAncestorRef: React.MutableRefObject<HTMLElement>;
  setPdfBookmarks: (bookmarks: CoreType.Bookmark[]) => void;
}

export const PDFViewer = ({
  docId,
  parsedDocId,
  readingPosition,
  currentScrollPosition,
  isPDF,
  currentDoc,
  scrollableAncestorRef,
  setPdfBookmarks,
  customPage,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}: PDFViewerProps) => {
  const viewer = useRef(null);
  const viewerContainer = useRef(null);
  const [viewerLoaded, setViewerLoaded] = useState(false);
  const [highlightElements, setHighlightElements] = useState<HighlightElement[]>([]);
  const filteredHighlightElements = useMemo(
    () => highlightElements.filter((elem) => Boolean(elem.parentNode)),
    [highlightElements],
  );
  const shortcutsMap = useShortcutsMap();

  useSnapshotShortcut();

  const [contentWidth, setContentWidth] = useState(0);

  const [annotationManager, setAnnotationManager] = useState<CoreType.AnnotationManager>();

  const [searchBarOpen, setSearchBarOpen] = useState(false);

  const { documentViewer, setDocumentViewer, documentLoaded, setDocumentLoaded } =
    useContext(PdfTronContext);

  const openSearchBar = useCallback(() => {
    setSearchBarOpen(true);
  }, []);

  const closeSearchBar = useCallback(() => {
    documentViewer?.clearSearchResults();
    setSearchBarOpen(false);
  }, [documentViewer]);

  const isDarkMode = globalState(
    useCallback((state) => state.webEffectiveTheme === DisplayTheme.Dark, []),
  );
  const shouldInvertPDFColors = globalState(
    useCallback((state) => state.client.shouldInvertPDFColors, []),
  );
  const selectedRectangleOutlineColor = useMemo(
    () =>
      isDarkMode
        ? new window.Core.Annotations.Color(yellow70Rgb.r, yellow70Rgb.g, yellow70Rgb.b)
        : new window.Core.Annotations.Color(yellow60Rgb.r, yellow60Rgb.g, yellow60Rgb.b),
    [isDarkMode],
  );

  const returnToReadingPosition = useCallback(() => {
    if (!documentViewer || !readingPosition) {
      return;
    }
    if (readingPosition?.pageNumber) {
      documentViewer.setCurrentPage(readingPosition.pageNumber, false);
    }
  }, [documentViewer, readingPosition]);

  useEffect(() => {
    if (!documentViewer || customPage === undefined || !documentLoaded) {
      return;
    }
    documentViewer.setCurrentPage(customPage, false);
  }, [documentViewer, customPage, documentLoaded]);

  useEffect(() => {
    if (!viewerContainer.current || !documentViewer || !documentLoaded) {
      return;
    }

    const onResize = () => {
      documentViewer.scrollViewUpdated();
      foregroundEventEmitter.emit('content-frame:content-moved');
      const contentContainer = document.getElementById('pdf-viewer');
      if (!contentContainer) {
        return;
      }
      setContentWidth(contentContainer.getBoundingClientRect().width);
    };
    const resizeObserver = new ResizeObserver(limitCalls(onResize));
    resizeObserver.observe(viewerContainer.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [viewerContainer, documentViewer, documentLoaded]);

  const focusedHighlightId = globalState(
    useCallback((state) => state.openedAnnotationBarPopoverHighlightId, []),
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Note],
    useCallback(async () => {
      foregroundEventEmitter.emit(`annotationPopover-${focusedHighlightId}:openHighlightNoteForm`, {
        shouldRemoveHighlightOnCancel: false,
      });
    }, [focusedHighlightId]),
    {
      description: 'Add / Edit note on current highlight',
      preferredEventTrigger: 'keyup',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Tags],
    useCallback(async () => {
      foregroundEventEmitter.emit(`annotationPopover-${focusedHighlightId}:openHighlightTagsForm`, {
        shouldRemoveHighlightOnCancel: false,
      });
    }, [focusedHighlightId]),
    {
      description: 'Add / Edit tags on current highlight',
      preferredEventTrigger: 'keyup',
    },
  );

  useKeyboardShortcut(
    [baseShortcuts.Space],
    useCallback(async () => {
      if (!documentViewer) {
        return;
      }

      const currentPage = documentViewer.getCurrentPage();
      documentViewer.setCurrentPage(currentPage + 1, false);
    }, [documentViewer]),
    {
      description: 'Go to next page',
      preferredEventTrigger: 'keyup',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.PageDown],
    useCallback(() => {
      if (scrollableAncestorRef.current !== null) {
        scrollableAncestorRef.current.focus();
      }
    }, [scrollableAncestorRef]),
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.PageUp],
    useCallback(() => {
      if (scrollableAncestorRef.current !== null) {
        scrollableAncestorRef.current.focus();
      }
    }, [scrollableAncestorRef]),
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Up],
    useCallback(() => {
      if (scrollableAncestorRef.current !== null) {
        scrollableAncestorRef.current.focus();
      }
    }, [scrollableAncestorRef]),
    {
      description: 'Scroll up',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Down],
    useCallback(() => {
      if (scrollableAncestorRef.current !== null) {
        scrollableAncestorRef.current.focus();
      }
    }, [scrollableAncestorRef]),
    {
      description: 'Scroll down',
    },
  );

  useEffect(() => {
    if (!viewer.current) {
      return;
    }
    setViewerLoaded(false);
    const Core = window.Core;
    if (!Core) {
      return;
    }

    Core.setWorkerPath(`/${pdftronAssetsDirectoryName.replace(/"/g, '')}/webviewer`);
    Core.getDefaultBackendType()
      .then((type) => {
        Core.initPDFWorkerTransports(
          type,
          {
            workerLoadingProgress: (percent: number) => {
              if (percent === 1) {
                setViewerLoaded(true);
              }
            },
          },
          licenceKey,
        );
      })
      .catch((e) => {
        logger.error(e);
      });

    setViewerLoaded(true);
    const Actions = Core.Actions;
    const Tools = Core.Tools;
    const ToolNames = Tools.ToolNames;
    const newDocumentViewer = new Core.DocumentViewer();

    // https://community.apryse.com/t/document-jumps-back-to-first-page/3665

    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    const onTriggered = Actions.GoTo.prototype.onTriggered;

    Actions.GoTo.prototype.onTriggered = function (target, event) {
      // @ts-ignore
      if (target === newDocumentViewer.getDocument() && event.name === 'Open') {
        return;
      }

      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      onTriggered.apply(this, arguments);
    };
    /* eslint-enable @typescript-eslint/ban-ts-comment */

    newDocumentViewer.setScrollViewElement(scrollableAncestorRef.current);
    newDocumentViewer.setViewerElement(viewer.current);
    newDocumentViewer.enableAnnotations();
    setDocumentViewer(newDocumentViewer);
    newDocumentViewer.addEventListener('documentLoaded', () => {
      newDocumentViewer.setToolMode(newDocumentViewer.getTool(ToolNames.HIGHLIGHT));
      setAnnotationManager(newDocumentViewer.getAnnotationManager());
    });
    newDocumentViewer.getTool(ToolNames.HIGHLIGHT).setStyles({
      StrokeColor: new Core.Annotations.Color(255, 248, 195),
    });
    newDocumentViewer.setTextHighlightColor('rgba(0,114,255,0.30)');
    newDocumentViewer.getTool(ToolNames.RECTANGLE).setStyles({
      StrokeColor: new Core.Annotations.Color(45, 117, 229),
      StrokeThickness: 1,
    });

    Core.Annotations.SelectionModel.defaultSelectionOutlineColor = new Core.Annotations.Color(
      0,
      0,
      0,
      0,
    );
    Core.Annotations.ControlHandle.handleWidth = 5;
    Core.Annotations.ControlHandle.handleHeight = 5;
    Core.Annotations.ControlHandle.selectionPointOutlineThickness = 0;

    // Select rectangle when clicking anywhere in the bounding box
    // https://community.apryse.com/t/how-to-select-a-stamp-annotation-when-any-part-of-the-bounding-box-is-clicked/5665/4
    Core.Annotations.RectangleAnnotation.prototype.selectionModel =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Core.Annotations.FreeTextSelectionModel;
  }, [scrollableAncestorRef, setDocumentViewer, setDocumentLoaded]);

  useEffect(() => {
    const Core = window.Core;

    if (!Core) {
      return;
    }

    Core.Annotations.ControlHandle.color = selectedRectangleOutlineColor;
    Core.Annotations.ControlHandle.outlineColor = selectedRectangleOutlineColor;
  }, [selectedRectangleOutlineColor]);

  const addStyleToHighlightElement = useCallback(
    ({
      annotWrapper,
      annotation,
    }: { annotWrapper: HighlightElement; annotation: CoreType.Annotations.Annotation }) => {
      if (!documentViewer) {
        return;
      }

      const x = annotation.getX();
      const y = annotation.getY();
      const width = annotation.getWidth();
      const height = annotation.getHeight();
      const zoom = documentViewer.getZoomLevel();
      annotWrapper.setAttribute(
        'style',
        `top: ${y * zoom}px; left: ${x * zoom}px; width: ${width * zoom}px; height: ${height * zoom}px;`,
      );
    },
    [documentViewer],
  );

  const createBoundingElementForHighlight = useCallback(
    (annotation: CoreType.Annotations.Annotation, highlight?: Highlight) => {
      if (!documentViewer) {
        return;
      }
      const pageNum = annotation.getPageNumber();
      const pageElem = document.getElementById(`pageContainer${pageNum}`);
      if (!pageElem) {
        return;
      }
      const id = annotation.getCustomData('readwise_id');
      const oldWrapper = pageElem.querySelector(`#annot-wrapper-${id}`) as HighlightElement;
      const hasNotes = Boolean(highlight?.children?.length);
      const hasTags = Boolean(Object.keys(highlight?.tags ?? {}).length);

      if (oldWrapper) {
        addStyleToHighlightElement({ annotWrapper: oldWrapper, annotation });
        oldWrapper.classList.toggle('rw-highlight--has-note', hasNotes);
        oldWrapper.classList.toggle('rw-highlight--has-tag', hasTags);
        return oldWrapper;
      }

      const annotWrapper = document.createElement('rw-highlight') as HighlightElement;
      annotWrapper.className = 'annot-wrapper rw-highlight';
      annotWrapper.classList.toggle('rw-highlight--has-note', hasNotes);
      annotWrapper.classList.toggle('rw-highlight--has-tag', hasTags);
      annotWrapper.id = `annot-wrapper-${id}`;
      addStyleToHighlightElement({ annotWrapper, annotation });
      annotWrapper.dataset.highlightId = id;
      annotWrapper.dataset.page = pageNum.toString();
      annotWrapper.appendChild(
        createElementFromString(
          `<span aria-hidden="true" class="${Renderer.highlightIconWrapperClassName}">
          <svg class="${Renderer.highlightNoteIconClassName}" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 2C3.34315 2 2 3.34315 2 5V9C2 10.6569 3.34315 12 5 12H6L8 15L10 12H11C12.6569 12 14 10.6569 14 9V5C14 3.34315 12.6569 2 11 2H5Z" />
          </svg>
          <svg class="${Renderer.highlightTagIconClassName}" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5858 7.58579L8.58579 2.58579C8.21071 2.21071 7.70201 2 7.17157 2H4C2.89543 2 2 2.89543 2 4V7.17157C2 7.70201 2.21071 8.21071 2.58579 8.58579L7.58579 13.5858C8.36684 14.3668 9.63316 14.3668 10.4142 13.5858L13.5858 10.4142C14.3668 9.63317 14.3668 8.36684 13.5858 7.58579ZM6 7C6.55228 7 7 6.55228 7 6C7 5.44771 6.55228 5 6 5C5.44772 5 5 5.44771 5 6C5 6.55228 5.44772 7 6 7Z" />
          </svg>
        </span>`
            // Remove space whitespace between tags
            .replace(/>\s+</g, '><'),
        ),
      );
      pageElem.appendChild(annotWrapper);

      return annotWrapper;
    },
    [documentViewer, addStyleToHighlightElement],
  );

  const removeBoundingElementForHighlight = useCallback((pageNumber: number, id: string) => {
    const pageElem = document.getElementById(`pageContainer${pageNumber}`);
    if (!pageElem) {
      return;
    }
    const oldWrapper = pageElem.querySelector(`#annot-wrapper-${id}`);
    oldWrapper?.parentNode?.removeChild(oldWrapper);
  }, []);

  useEffect(() => {
    setHighlightElements([]);
    const readwiseElements = document.querySelectorAll('.annot-wrapper');
    for (const elem of readwiseElements) {
      if (elem.parentNode) {
        elem.parentNode?.removeChild(elem);
      }
    }
  }, [parsedDocId]);

  useEffect(() => {
    documentViewer?.closeDocument();
    setPdfBookmarks([]);
    setDocumentLoaded(false);
    setSearchBarOpen(false);
  }, [parsedDocId, docId, documentViewer, setPdfBookmarks, isPDF, setDocumentLoaded]);

  let loadingMessage;

  if (currentDoc && currentDoc.category === Category.PDF) {
    loadingMessage = <PDFLoader />;
    if (
      [ContentRequestLoadingStatus.Loading, ContentRequestLoadingStatus.Unloaded].includes(
        currentDoc.transientData.contentRequestLoadingStatus,
      ) ||
      [ContentParsingStatus.Pending, ContentParsingStatus.ServerTaskNotStartedYet].includes(
        currentDoc.transientData.contentParsingStatus,
      )
    ) {
      loadingMessage = (
        <div>
          <PDFLoader />
          <p>This PDF is downloading to your device for local reading...</p>
        </div>
      );
    }
    if (currentDoc.transientData.contentParsingStatus === ContentParsingStatus.Failed) {
      loadingMessage = (
        <p>
          Uh oh! We failed to parse this document. Sorry about that. <br /> We&apos;ve been alerted but
          you can{' '}
          <a href={currentDoc.url} target="_blank" rel="noreferrer">
            view the original
          </a>{' '}
          in the meantime.
        </p>
      );
    }
  }

  if (!viewerLoaded) {
    loadingMessage = (
      <p>
        <PDFLoader />
        <p>The PDF Viewer is booting up...</p>
      </p>
    );
  }

  const documentReady =
    isPDF &&
    viewerLoaded &&
    !isNil(currentDoc) &&
    !isNil(docId) &&
    currentDoc &&
    currentDoc.transientData.contentParsingStatus === ContentParsingStatus.Success &&
    currentDoc.transientData.contentRequestLoadingStatus === ContentRequestLoadingStatus.Loaded &&
    !isNil(parsedDocId);

  const isReturnToReadingVisible =
    viewerLoaded &&
    currentScrollPosition &&
    readingPosition &&
    currentScrollPosition.scrollDepth !== readingPosition.scrollDepth;
  const readingPercent = readingPosition?.scrollDepth ? readingPosition.scrollDepth * 100 : 0;
  const scrollPercent = currentScrollPosition?.scrollDepth ? currentScrollPosition.scrollDepth * 100 : 0;
  const docTitle = getDocumentTitle(currentDoc);

  // useEffect(() => {
  //   if (!documentReady) {
  //     setDocumentLoaded(false);
  //   }
  // }, [documentReady]);

  return (
    <>
      {isPDF && (
        <div className={styles.progressBarContainer}>
          <ReadingProgressBar
            progress={readingPercent}
            currentScroll={scrollPercent}
            fakeReadingProgress
            large
          />
        </div>
      )}
      <div className={`${styles.pdfContainer}`} id="pdf-viewer-container" ref={viewerContainer}>
        <div
          id="pdf-viewer"
          ref={viewer}
          className={`webviewer ${documentLoaded ? styles.pdfVisible : styles.pdfHidden} ${
            isDarkMode && shouldInvertPDFColors ? styles.invertedPDF : ''
          }`}
          style={{ margin: 'auto', position: 'relative', zIndex: 0 }}
        />
      </div>
      {documentReady && (
        <PDFContentManager
          annotationManager={annotationManager}
          createBoundingElementForHighlight={createBoundingElementForHighlight}
          openSearchBar={openSearchBar}
          currentScrollPosition={currentScrollPosition}
          docId={docId}
          documentLoaded={documentLoaded}
          documentViewer={documentViewer}
          eventEmitter={foregroundEventEmitter}
          key={`pdf-content-manager-${docId}`}
          parsedDocId={parsedDocId}
          removeHtmlHighlight={removeBoundingElementForHighlight}
          setDocumentLoaded={setDocumentLoaded}
          setHighlightElements={setHighlightElements}
          setPdfBookmarks={setPdfBookmarks}
          docTitle={docTitle}
        />
      )}
      {documentLoaded && (
        <PDFPopovers
          eventEmitter={foregroundEventEmitter}
          contentWidth={contentWidth}
          highlightElements={filteredHighlightElements}
        />
      )}
      {!documentLoaded && isPDF && <div className={styles.loaderContainer}>{loadingMessage}</div>}
      {isReturnToReadingVisible && isPDF && (
        <ReturnToReadingButton
          onClick={returnToReadingPosition}
          currentScrollPos={currentScrollPosition}
          currentReadingPos={readingPosition}
        />
      )}

      {searchBarOpen && <PDFSearchBar documentViewer={documentViewer} onClose={closeSearchBar} />}
    </>
  );
};
